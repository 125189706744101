import 'raf/polyfill'
import 'setimmediate'

import { Provider } from 'app/provider'
import Head from 'next/head'
import React from 'react'
import type { SolitoAppProps } from 'solito'
import { useRouter } from 'next/router'
import { config } from 'app/redux/config'
import { Platform } from 'react-native'
import * as WebBrowser from 'expo-web-browser';

if(Platform.OS === 'web') {
  WebBrowser.maybeCompleteAuthSession()
}

function MyApp({ Component, pageProps }: SolitoAppProps) {
  const router = useRouter()

  return (
    <>
      <Head>
        <title>GENNOCTUA</title>
        <meta
          name="description"
          content="Elevate your interior and exterior design game with our cutting-edge app. Whether you're looking to visualize a new piece of furniture in your living room or test out different tile patterns for your patio, our app has got you covered. Here's what you can do:"
        />
        <meta
          content={
            'Interior design, exterior design, object placement, object replacement, teleport objects, wall color change, floor tiles, home customization, design visualization, augmented reality.'
          }
          name="keywords"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href={`${config.baseUrl}${router.asPath}`}></link>
      </Head>
      <Provider>
        <Component {...pageProps} />
      </Provider>
    </>
  )
}

export default MyApp
