import { config } from '../config';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { EXPO_NOTIFICATION_TOKEN } from '../constants';

const userLogout = async () => {
  if(typeof window === 'undefined') return
  if (Platform.OS === 'web') {
    const urlObject = new URL(config.baseUrl);
    document.cookie = `${config.authTokenName}=; path=/; domain=${urlObject.hostname}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `ln-language=; path=/; domain=${urlObject.hostname}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = `yoloCanceled=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${urlObject.hostname};`;
  } 
  if (Platform.OS !== 'web') {
    await AsyncStorage.removeItem(config.authTokenName);
  }
  await AsyncStorage.removeItem('user');
  await AsyncStorage.removeItem(EXPO_NOTIFICATION_TOKEN);
};

export default userLogout;
